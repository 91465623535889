<style scoped>
.search-report >>> .el-card__header {
  border: none;
}
.search-report .el-card__header .icon-fanhui-:before {
  margin-right: 10px;
}
.search-report .back-btn {
  color: #333;
  margin-left: 65px;
  position: absolute;
  left: 0;
  top: 50px;
}
.card-title {
  font-size: 30px;
  /* line-height: 80px; */
  color: #333333;
  margin-top: 60px;
}
.card-content {
  width: 1116px;
  height: 350px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-around;
}
.search-report .content {
  padding: 0 65px;
  box-sizing: border-box;
}
.search-report .search-box {
  padding: 0 65px;
  box-sizing: border-box;
  text-align: left;
}
</style>
<template>
  <div class="search-report" id="container">
    <el-card>
      <div slot="header">
        <el-button
          class="back-btn iconfont icon-fanhui-"
          type="text"
          @click="backHome"
        >
          返回
        </el-button>
        <p class="card-title">搜索报告</p>
      </div>
      <div class="search-box">
        <el-form
          :inline="true"
          :model="form"
          class="form"
          label-position="right"
          label-width="100px"
          label-suffix=":"
        >
          <el-form-item
            label="关键词"
            style="margin-right: 90px; letter-spacing: 3px"
          >
            <el-input
              style="width: 400px"
              v-model="form.text"
              placeholder="请输入姓名/检测员/样本编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="查询时间">
            <el-date-picker
              style="width: 385px"
              value-format="yyyy-MM-dd"
              v-model="form.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="检测类别">
            <el-select v-model="form.type" placeholder="请选择">
              <el-option label="人类传染性疾病(HID)" value="HID"></el-option>
              <el-option label="药物基因组(PG)" value="PG"></el-option>
              <el-option label="动物感染疾病(AID)" value="AID"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="form.checked">仅显示QC记录</el-checkbox>
          </el-form-item>
          <el-form-item style="margin-left: 45px">
            <el-button type="primary" @click="getList()">搜索</el-button>
            <el-button type="primary" plain @click="reset">重置</el-button>
          </el-form-item>
          <el-button
            style="float: right; margin-right: 10px"
            type="primary"
            :icon="isDownLoad ? 'el-icon-loading' : 'el-icon-download'"
            @click="downLoad"
            >下载</el-button
          >
        </el-form>
      </div>
      <div class="content">
        <div class="table-box">
          <el-table
            :header-cell-style="{ background: '#dfe2e6', color: '#333' }"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
            <el-table-column label="姓名" width="100" align="center">
              <template slot-scope="scope">{{
                scope.row.patient_name
              }}</template>
            </el-table-column>
            <el-table-column
              prop="sample_number"
              label="样本编号"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="detection_date"
              label="检测时间"
              show-overflow-tooltip
              width="150"
            >
            </el-table-column>
            <el-table-column
              prop="patient_project_name"
              label="检测类别"
              width="200"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div v-if="scope.row.patient_project_name == 'HID'">
                  人类传染性疾病(HID)
                </div>
                <div v-else-if="scope.row.patient_project_name == 'AID'">
                  动物感染性疾病(AID)
                </div>
                <div v-else>药物基因组(PG)</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="operation_name"
              label="检测员"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="is_qc"
              label="是否QC样本"
              width="100"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.is_qc == 1">是</el-tag>
                <el-tag type="danger" v-else>否</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="card-bottom">
          <el-pagination
            class="pagination"
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="size"
            @current-change="getList"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { diseasesPage, diseasesExport } from "@/api/base";
export default {
  name: "SearchReport",
  data() {
    return {
      isDownLoad: false,
      total: null,
      current: null,
      size:5,
      form: {
        text: "",
        time: [],
        type: "HID",
        checked: false,
      },
      tableData: [],
      multipleSelection: [],
      owner_phone:null,
      operation_phone:null
    };
  },
  created() {
    let { owner_phone,operation_phone} = this.$route.query
    this.owner_phone = owner_phone
    this.operation_phone = operation_phone
    this.getList()
    ;

  },
  methods: {
    //获取列表信息
    async getList(page = 1) {
      this.current = page;
      let keyword = this.form.text;
      let patient_project_name = this.form.type;
      let is_qc = 0;
      let to_time;
      let from_time;
      if (this.form.checked) {
        is_qc = 1;
      } else {
        is_qc = 0;
      }
      if (this.form.time.length == 2) {
        from_time = this.form.time[0];
        to_time = this.form.time[1];
      } else {
        from_time = "";
        to_time = "";
      }

      let {current,size,owner_phone,operation_phone} = this;
      let res = await diseasesPage({
        current,
        size,
        keyword,
        owner_phone,
        operation_phone,
        patient_project_name,
        is_qc,
        from_time,
        to_time,
      });
      this.tableData = res.data.records;
      this.total = res.data.dataCount;
    },
    //返回
    backHome() {
      this.$router.back();
    },
    //重置
    reset() {
      this.form = {
        text: "",
        time: [],
        type: "HID",
        checked: false,
      };
      this.getList();
    },
    //导出excel
    async downLoad() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      let patient_project_name = this.form.type;
      const res = await diseasesExport(patient_project_name);
      if (res) {
        const blob = new Blob([res]);
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", `${this.form.type}.xls`);
        link.click();
        link = null;
      }
      loading.close();
    },
    //表格checked框值发生改变
    // handleSelectionChange(e) {
    //   console.log(e);
    // },
  },
};
</script>